// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import "../css/app.scss"

// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import deps with the dep name or local files with a relative path, for example:
//
//     import {Socket} from "phoenix"
//     import socket from "./socket"
//
import "phoenix_html"
import {Socket} from "phoenix"
import NProgress from "nprogress"
import {LiveSocket} from "phoenix_live_view"
import 'alpinejs'
import { PhoenixLiveViewDropzone } from "phoenix_live_view_drop_zone";

let Hooks = {};
Hooks.PhoenixLiveViewDropzone = new PhoenixLiveViewDropzone();

Hooks.UploadHook = {
  mounted() {
    let fid = this.el.getAttribute("id")
    let target = this.el.getAttribute("data-target")
    let file_types = this.el.getAttribute("file_types")
    let multiple = this.el.getAttribute("multiple")
    let main_this = this
    let button = this.el.querySelector('button')
    let frm = document.createElement("form")
    frm.id = "fu_form_" + fid;
    var input = document.createElement("input");
    input.type = "file";
    input.id = "fu_" + fid;
    if (multiple && multiple == "true") {
      input.multiple = true;
    }
    if (file_types) {
      input.accept = file_types;
    }
    frm.appendChild(input)
    frm.className = "hidden"; // set the CSS class
    this.el.appendChild(frm); // put it into the DOM
    button.addEventListener("click", function () {
      input.click()
    })
    input.onchange = function (e) {
      let f = e.target.files[0].name
      main_this.pushEvent("generate_file_url", { id: fid, name: f })
    };
  },
  updated() {
    let main_this = this
    let target = this.el.getAttribute("data-target")
    let fid = this.el.getAttribute("data-fid")
    let furl = this.el.getAttribute("data-furl")
    if (fid == this.el.id) {
      let fm = document.getElementById("fu_form_" + fid)
      fm.setAttribute("method", "PUT")
      fm.setAttribute("action", furl)
      let file = document.getElementById("fu_" + fid).files[0]
      // console.log(file)
      if (file.type.indexOf("image") >= 0) {
        console.log("resizing required")
        // resize(file, furl, fid)
        var reader = new FileReader();
        reader.onload = function (readerEvent) {
          console.log("Read file")
          var image = new Image();
          image.onload = function (imageEvent) {
            // Resize the image
            var canvas = document.createElement('canvas'),
              max_width = 128,// TODO : pull max size from a site config
              max_height = 128,// TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > max_width) {
              height *= max_width / width;
              width = max_width;
            }
            if (height > max_height) {
              width *= max_height / height;
              height = max_height;
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL(file.type);
            // console.log(dataUrl)
            let blob = dataURItoBlob(dataUrl);
            uploadFile(main_this, blob, furl, null, file.name, target)
          }
          image.src = readerEvent.target.result;
        }
        reader.readAsDataURL(file);
      } else {
        uploadFile(main_this, file, furl, null, file.name, target)
      }
    }
  }
}

let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, {hooks: Hooks, params: {_csrf_token: csrfToken}})

// Show progress bar on live navigation and form submits
window.addEventListener("phx:page-loading-start", info => NProgress.start())
window.addEventListener("phx:page-loading-stop", info => NProgress.done())

// connect if there are any LiveViews on the page
liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)
window.liveSocket = liveSocket
